import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

export default function Footer() {
  return (
    <div>
      <div className="my-8 pt-10 md:pt-20 border-t">
        <div className="w-1/3 mx-auto md:flex items-center justify-between">
          <div>
            <Link to="/">
              <img src={logo} alt="" className="w-[50%] mx-auto mb-3" />
            </Link>
            <p className="text-sm text-gray-600 text-center w-full lg:max-w-[200px]">
              Algoo Strategies crypto blog - News, updates and articles
            </p>
          </div>
          <div className="max-md:mt-8">
            <div className="flex items-center justify-center md:justify-end mb-4 space-x-3">
              <a
                href="https://t.me/algoostrategies"
                className="w-[20%] md:w-[12%]"
              >
                <div>
                  <svg
                    width="70%"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#svg-gobbler-14-1_clip0-15938-79920)">
                      <path
                        d="M20.2019 30.4422L19.4451 41.7498C20.5279 41.7498 20.9969 41.2557 21.5592 40.6623L26.6357 35.5087L37.1546 43.6917C39.0838 44.8338 40.443 44.2324 40.9634 41.8065L47.868 7.43833L47.8699 7.4363C48.4818 4.40692 46.8386 3.2223 44.959 3.96547L4.37395 20.4712C1.6041 21.6133 1.64604 23.2535 3.9031 23.9967L14.2791 27.425L38.3804 11.4053C39.5146 10.6074 40.5459 11.0489 39.6976 11.8467L20.2019 30.4422Z"
                        fill="#333"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="svg-gobbler-14-1_clip0-15938-79920">
                        <rect width="48" height="48" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/algoostrategies/"
                className="w-[20%] md:w-[12%]"
              >
                <div>
                  <svg
                    style={{ color: "rgb(5, 129, 245)" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="70%"
                    fill="currentColor"
                    class="bi bi-linkedin"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                      fill="#333"
                    ></path>{" "}
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="border-t w-[90%] lg:w-1/3 mx-auto pt-5 mt-10">
          <h1 className="text-xs">
            &copy; 2023 Algoo Strategies. All rights reserved
          </h1>
        </div>
      </div>
    </div>
  );
}
