import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages";
import Post from "./pages/post";
import Defi from "./pages/defi";
import Announcements from "./pages/announcements";
import AlgorithmicTrading from "./pages/articles";
import News from "./pages/tutorial";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/announcements" element={<Announcements />} />
        <Route path="/decentralized-finance" element={<Defi />} />
        <Route path="/articles" element={<AlgorithmicTrading />} />
        <Route path="/tutorial" element={<News />} />
        <Route path="/post/:slug" element={<Post />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
