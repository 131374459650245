import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BASE_URL } from "../components/BaseUrl";
import axios from "axios";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export default function Post() {
  const [blog, setBlog] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [previousBlog, setPreviousBlog] = useState([]);
  const [nextBlog, setNextBlog] = useState([]);
  const { slug } = useParams();
  const title = slug.split("-").join(" ");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`${BASE_URL}/blogs`);
        setBlogs(data);
        if (title !== data.detail[0].title.toLowerCase()) {
          console.log(title);
          data.detail.map((blogPost) => {
            console.log(blogPost);
            if (title === blogPost.title.toLowerCase()) {
              setBlog(blogPost);
              setPreviousBlog(data.detail[data.detail.indexOf(blogPost) - 1]);
              setNextBlog(data.detail[data.detail.indexOf(blogPost) + 1]);
            }
          });
        } else {
          console.log(data.detail[0]);
          setBlog(data.detail[0]);
          setNextBlog(data.detail[1]);
        }
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        // setError(error.message);
        setLoading(false);
      }
    };

    fetchPost();
  }, [title]);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <div>
        <Helmet>
          <title>{`Algoo Strategies Blog | ${blog?.title}`}</title>
          <meta
            property="og:title"
            content={`Algoo Strategies Blog | ${blog?.title}`}
          />
          <meta property="og:image" content={blog?.thumbnail} />
          <meta property="og:type" content="article" />
        </Helmet>
      </div>
      <div>
        <Navbar />
      </div>
      <div className="w-[90%] md:w-[80%] lg:w-3/4 mx-auto">
        <div className="lg:w-2/3 mx-auto">
          {blog && (
            <div>
              <div>
                <h1 className="font-medium text-2xl mb-1 mt-10">
                  {blog?.title}
                </h1>
                <div>
                  <p>In {blog?.categories[0]}</p>
                  <p>Published on: {blog?.pubDate.slice(0, 11)}</p>
                </div>
              </div>
              <div>
                <div
                  className="leading-[40px] text-lg my-5 content"
                  dangerouslySetInnerHTML={{ __html: blog?.content }}
                ></div>
              </div>
            </div>
          )}
        </div>
        <div className="lg:flex items-center justify-between my-8 py-6 border-y">
          <div>
            {title !== blogs.detail[0].title.toLowerCase() && (
              <Link
                to={`/post/${previousBlog?.title
                  .toLowerCase()
                  .split(" ")
                  .join("-")}`}
                className="flex items-center space-x-3"
              >
                <div
                  style={{ backgroundImage: `url(${previousBlog.thumbnail})` }}
                  className=" bg-cover bg-center w-12 h-12 lg:w-16 lg:h-16 border-4 rounded-full"
                ></div>
                <div>
                  <p className="max-md:text-xs mb-1">Previous post:</p>
                  <h1 className="font-medium max-md:text-sm max-w-[70%] md:max-w-[300px]">
                    {previousBlog.title}
                  </h1>
                </div>
              </Link>
            )}
          </div>
          <div>
            {title !==
              blogs.detail[blogs.detail.length - 1].title.toLowerCase() && (
              <Link
                to={`/post/${nextBlog.title
                  .toLowerCase()
                  .split(" ")
                  .join("-")}`}
                className="flex items-center space-x-3 text-right max-md:mb-6"
              >
                <div className="max-md:relative w-full">
                  <p className="max-md:text-xs mb-1">Next post:</p>
                  <h1 className="font-medium max-md:text-sm max-w-[70%] md:max-w-[300px] max-md:absolute max-md:right-0">
                    {nextBlog.title}
                  </h1>
                </div>
                <div
                  style={{ backgroundImage: `url(${nextBlog.thumbnail})` }}
                  className="bg-cover bg-center w-12 h-12 lg:w-16 lg:h-16 border-4 rounded-full"
                ></div>
              </Link>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
