import React, { useState, useEffect } from "react";
import { BASE_URL } from "../components/BaseUrl";
import axios from "axios";
import { Link } from "react-router-dom";
import { ShortenText, ToText } from ".";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export default function Announcements() {
  const [blogs, setBlogs] = useState([]);
  async function getBlogs() {
    const { data } = await axios.get(
      `${BASE_URL}/blogs/by-category?category=announcements`
    );
    console.log(data);
    setBlogs(data.detail);
  }
  useEffect(() => {
    getBlogs();
  }, []);
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="lg:w-[70%] mx-auto mt-14">
        <div className="max-lg:space-y-8 max-lg:p-8 md:grid md:grid-cols-2 lg:grid-cols-3 gap-5">
          {blogs &&
            blogs.map((blog) => (
              <div className="border hover:border-black duration-500 border-dashed p-5">
                <div>
                  <img alt="" src={blog.thumbnail} className="mb-5" />
                </div>
                <div>
                  <h1 className="font-medium text-2xl">{blog.title}</h1>
                  <div>
                    <p className="text-sm text-gray-500 my-2">
                      Published on: {blog.pubDate.slice(0, 11)}
                    </p>
                  </div>
                </div>
                <div className="mt-3 mb-7">
                  <p>{ShortenText(ToText(blog.description), 0, 200) + "..."}</p>
                </div>
                <Link
                  to={`/post/${blog.title.toLowerCase().split(" ").join("-")}`}
                >
                  <button className="md:py-[6px] md:px-5 md:text-gray-800 max-md:italic max-md:font-medium text-sm md:rounded-full md:border border-black ">
                    Continue Reading
                  </button>
                </Link>
              </div>
            ))}
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
